/* You can add global styles to this file, and also import other style files */
/* Add application styles & imports to this file! */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.basic-container {
  padding: 5px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

